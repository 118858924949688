import type { DialogModalProps } from '@pledge-earth/product-language';
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  DialogModal,
  DialogTitle,
  OverlayCloseButton,
} from '@pledge-earth/product-language';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';

interface ModalProps extends DialogModalProps {
  title: ReactNode;
  children: ReactNode;
  footer?: ReactNode | (({ close }: { close: () => void }) => ReactNode);
  cypressSelector?: string;
}

export function Modal({ title, children, footer, cypressSelector, ...modalProps }: ModalProps) {
  const { formatMessage } = useIntl();

  return (
    <DialogModal {...modalProps}>
      <Dialog data-cy={cypressSelector}>
        {(renderProps) => (
          <>
            <DialogHeader>
              <DialogTitle>{title}</DialogTitle>
              <OverlayCloseButton label={formatMessage({ id: 'close' })} />
            </DialogHeader>
            <DialogBody>{children}</DialogBody>
            {footer != null && (
              <DialogFooter>{typeof footer === 'function' ? footer(renderProps) : footer}</DialogFooter>
            )}
          </>
        )}
      </Dialog>
    </DialogModal>
  );
}
