import { FormattedMessage } from 'react-intl';
import {
  CalculatorIcon,
  DashboardIcon,
  DeveloperToolIcon,
  DocumentApiIcon,
  EmissionIcon,
  ExternalIcon,
  FileArrowUpIcon,
  FlaskIcon,
  FullScreenIcon,
  GridConnectIcon,
  HomeIcon,
  LinkIcon,
  OffsetsIcon,
  OrganisationIcon,
  PuzzleIcon,
  ReceiptIcon,
  ReportIcon,
} from '@pledge-earth/product-language';
import type { IToggle } from 'unleash-proxy-client';
import { omit } from 'lodash';

import { Subheader } from '../../components/MenuLeft/SubHeader';
import { ReactRouterLinkWithTestMode } from '../../components/LinkWithTestMode/LinkWithTestMode';
import { ClientRoleEnum } from '../graphql/generated';
import {
  PLATFORM_CLIENTS_ENTITLEMENT,
  PLATFORM_DATA_COLLECTION_OUTBOUND_ENTITLEMENT,
  PLATFORM_DATA_COLLECTION_INBOUND_ENTITLEMENT,
  PLATFORM_SUPPLIERS_ENTITLEMENT,
  PLATFORM_DIRECTORY_SUPPLIER_ENTITLEMENT,
} from '../../components/Entitlement/Entitlement';

export interface MenuItem {
  label: React.ReactNode;
  icon: React.ReactNode;
  suffixIcon?: React.ReactNode;
  key: string;
  url: string | undefined;
  target?: '_blank';
  roles: ClientRoleEnum[];
  sub_urls?: string[];
  featureFlags?: string[];
  entitlements?: string[];
  children?: MenuItem[];
  position: 'top' | 'bottom';
}

export interface MenuSubHeaderItem {
  label: React.ReactNode;
  key: string;
  roles: ClientRoleEnum[];
  sub_urls?: string[];
  popupClassName?: string;
  featureFlags?: string[];
  entitlements?: string[];
  position: 'top' | 'bottom';
}

function filterMenuData({
  role,
  flags,
  entitlements,
  items,
}: {
  flags: IToggle[];
  role: string | null;
  entitlements: string[];
  items: (MenuItem | MenuSubHeaderItem)[];
}): (Omit<MenuItem, 'featureFlags'> | Omit<MenuSubHeaderItem, 'featureFlags'>)[] {
  return items
    .filter((item) => item.roles.includes(role as ClientRoleEnum))
    .filter((item) => {
      if (!item.featureFlags) {
        return true;
      }

      return item.featureFlags.some((itemFlagName) =>
        flags?.some((flag) => itemFlagName === flag.name && flag.enabled),
      );
    })
    .filter((item) => {
      if (!item.entitlements) {
        return true;
      }

      return item.entitlements.some((itemEntitlement) => entitlements.includes(itemEntitlement));
    })
    .map((item) => ({
      ...omit(item, 'featureFlags'),
      ...('children' in item && item.children
        ? { children: filterMenuData({ role, flags, entitlements, items: item.children }) }
        : {}),
    }));
}

export function getMenuData({
  currentLocation,
  flags,
  entitlements,
  role,
}: {
  currentLocation: string;
  flags: IToggle[];
  entitlements: string[];
  role: string | null;
}): (MenuItem | MenuSubHeaderItem)[] {
  return filterMenuData({
    role,
    flags,
    entitlements,
    items: [
      {
        label: (
          <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/dashboard">
            <FormattedMessage id="leftMenu.home" />
          </ReactRouterLinkWithTestMode>
        ),
        icon: <HomeIcon />,
        key: '__home',
        url: '/dashboard',
        roles: [
          ClientRoleEnum.Owner,
          ClientRoleEnum.Admin,
          ClientRoleEnum.Developer,
          ClientRoleEnum.Viewer,
          ClientRoleEnum.Operations,
        ],
        position: 'top',
      },
      {
        label: (
          <span className="MenuLeft__menu-item__title">
            <FormattedMessage id="leftMenu.dashboards" />
          </span>
        ),
        icon: <DashboardIcon />,
        key: 'dashboards',
        url: '/dashboards',
        roles: [
          ClientRoleEnum.Owner,
          ClientRoleEnum.Admin,
          ClientRoleEnum.Developer,
          ClientRoleEnum.Viewer,
          ClientRoleEnum.Operations,
        ],
        position: 'top',
        featureFlags: ['analytics-page'],
        sub_urls: ['/dashboards/emissions/'],
        children: [
          {
            label: (
              <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/dashboards/emissions/overview">
                <FormattedMessage id="dashboards.emissions.overview.title" />
              </ReactRouterLinkWithTestMode>
            ),
            key: 'dashboards/emissions/overview',
            url: '/dashboards/emissions/overview',
            roles: [
              ClientRoleEnum.Owner,
              ClientRoleEnum.Admin,
              ClientRoleEnum.Developer,
              ClientRoleEnum.Viewer,
              ClientRoleEnum.Operations,
            ],
            featureFlags: ['analytics-emissions-overview'],
            position: 'top',
            icon: undefined,
          },
          {
            label: (
              <ReactRouterLinkWithTestMode
                className="MenuLeft__menu-item__title"
                to="/dashboards/emissions/north-america-road-freight"
              >
                <FormattedMessage id="dashboards.emissions.north_america_road.title" />
              </ReactRouterLinkWithTestMode>
            ),
            key: 'dashboards/emissions/north-america-road-freight',
            url: '/dashboards/emissions/north-america-road-freight',
            roles: [
              ClientRoleEnum.Owner,
              ClientRoleEnum.Admin,
              ClientRoleEnum.Developer,
              ClientRoleEnum.Viewer,
              ClientRoleEnum.Operations,
            ],
            featureFlags: ['analytics-shipments-north-america-road'],
            position: 'top',
            icon: undefined,
          },
          {
            label: (
              <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/dashboards/emissions/client">
                <FormattedMessage id="dashboards.emissions.client.title" />
              </ReactRouterLinkWithTestMode>
            ),
            key: 'dashboards/emissions/client',
            url: '/dashboards/emissions/client',
            roles: [
              ClientRoleEnum.Owner,
              ClientRoleEnum.Admin,
              ClientRoleEnum.Developer,
              ClientRoleEnum.Viewer,
              ClientRoleEnum.Operations,
            ],
            featureFlags: ['analytics-emissions-client'],
            entitlements: [PLATFORM_CLIENTS_ENTITLEMENT],
            position: 'top',
            icon: undefined,
          },
          {
            label: (
              <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/dashboards/emissions/supplier">
                <FormattedMessage id="dashboards.emissions.supplier.title" />
              </ReactRouterLinkWithTestMode>
            ),
            key: 'dashboards/emissions/supplier',
            url: '/dashboards/emissions/supplier',
            roles: [
              ClientRoleEnum.Owner,
              ClientRoleEnum.Admin,
              ClientRoleEnum.Developer,
              ClientRoleEnum.Viewer,
              ClientRoleEnum.Operations,
            ],
            featureFlags: ['analytics-emissions-supplier'],
            entitlements: [PLATFORM_SUPPLIERS_ENTITLEMENT],
            position: 'top',
            icon: undefined,
          },
          {
            label: (
              <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/dashboards/emissions/intensity">
                <FormattedMessage id="dashboards.emissions.intensity.title" />
              </ReactRouterLinkWithTestMode>
            ),
            key: 'dashboards/emissions/intensity',
            url: '/dashboards/emissions/intensity',
            roles: [
              ClientRoleEnum.Owner,
              ClientRoleEnum.Admin,
              ClientRoleEnum.Developer,
              ClientRoleEnum.Viewer,
              ClientRoleEnum.Operations,
            ],
            featureFlags: ['analytics-emissions-intensity'],
            position: 'top',
            icon: undefined,
          },
          {
            label: (
              <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/dashboards/emissions/accuracy">
                <FormattedMessage id="dashboards.emissions.accuracy.title" />
              </ReactRouterLinkWithTestMode>
            ),
            key: 'dashboards/emissions/accuracy',
            url: '/dashboards/emissions/accuracy',
            roles: [
              ClientRoleEnum.Owner,
              ClientRoleEnum.Admin,
              ClientRoleEnum.Developer,
              ClientRoleEnum.Viewer,
              ClientRoleEnum.Operations,
            ],
            featureFlags: ['analytics-emissions-accuracy'],
            position: 'top',
            icon: undefined,
          },
          {
            label: (
              <ReactRouterLinkWithTestMode
                className="MenuLeft__menu-item__title"
                to="/dashboards/emissions/sea-freight"
              >
                <FormattedMessage id="dashboards.emissions.sea_freight.title" />
              </ReactRouterLinkWithTestMode>
            ),
            key: 'dashboards/emissions/sea-freight',
            url: '/dashboards/emissions/sea-freight',
            roles: [
              ClientRoleEnum.Owner,
              ClientRoleEnum.Admin,
              ClientRoleEnum.Developer,
              ClientRoleEnum.Viewer,
              ClientRoleEnum.Operations,
            ],
            featureFlags: ['analytics-emissions-sea-freight'],
            position: 'top',
            icon: undefined,
          },
          {
            label: (
              <ReactRouterLinkWithTestMode
                className="MenuLeft__menu-item__title"
                to="/dashboards/emissions/air-freight"
              >
                <FormattedMessage id="dashboards.emissions.air_freight.title" />
              </ReactRouterLinkWithTestMode>
            ),
            key: 'dashboards/emissions/air-freight',
            url: '/dashboards/emissions/air-freight',
            roles: [
              ClientRoleEnum.Owner,
              ClientRoleEnum.Admin,
              ClientRoleEnum.Developer,
              ClientRoleEnum.Viewer,
              ClientRoleEnum.Operations,
            ],
            featureFlags: ['analytics-emissions-air-freight'],
            position: 'top',
            icon: undefined,
          },
        ],
      },
      {
        label: <Subheader message="leftMenu.title.measurement-and-analytics" />,
        key: '__measurements-subheader',
        roles: [
          ClientRoleEnum.Owner,
          ClientRoleEnum.Admin,
          ClientRoleEnum.Developer,
          ClientRoleEnum.Viewer,
          ClientRoleEnum.Operations,
        ],
        position: 'top',
      },
      {
        label: (
          <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/emissions">
            <FormattedMessage id="leftMenu.emissions" />
          </ReactRouterLinkWithTestMode>
        ),
        icon: <EmissionIcon />,
        key: '__emissions',
        url: '/emissions',
        sub_urls: ['/emissions/'],
        roles: [
          ClientRoleEnum.Owner,
          ClientRoleEnum.Admin,
          ClientRoleEnum.Developer,
          ClientRoleEnum.Viewer,
          ClientRoleEnum.Operations,
        ],
        position: 'top',
      },
      {
        label: (
          <ReactRouterLinkWithTestMode
            className="flex items-center gap-1 font-medium"
            to="/calculators/freight"
            state={{ previousLocation: currentLocation }}
          >
            <FormattedMessage id="leftMenu.calculators" />
            <FullScreenIcon />
          </ReactRouterLinkWithTestMode>
        ),
        icon: <CalculatorIcon />,
        key: '__calculators',
        url: '/calculators/freight',
        roles: [
          ClientRoleEnum.Admin,
          ClientRoleEnum.Owner,
          ClientRoleEnum.Developer,
          ClientRoleEnum.Viewer,
          ClientRoleEnum.Operations,
        ],
        position: 'top',
      },
      {
        label: (
          <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/reports/emissions">
            <FormattedMessage id="leftMenu.reports" />
          </ReactRouterLinkWithTestMode>
        ),
        icon: <ReportIcon />,
        key: '__reports',
        url: '/reports/emissions',
        roles: [
          ClientRoleEnum.Admin,
          ClientRoleEnum.Owner,
          ClientRoleEnum.Developer,
          ClientRoleEnum.Viewer,
          ClientRoleEnum.Operations,
        ],
        position: 'top',
      },
      {
        label: <Subheader message="leftMenu.title.offsetting-and-insetting" />,
        key: '__offsetting-subheader',
        roles: [ClientRoleEnum.Owner, ClientRoleEnum.Admin, ClientRoleEnum.Operations],
        position: 'top',
      },
      {
        label: (
          <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/offsetting">
            <FormattedMessage id="leftMenu.marketplace" />
          </ReactRouterLinkWithTestMode>
        ),
        icon: <OffsetsIcon />,
        key: '__marketplace',
        url: '/offsetting',
        roles: [ClientRoleEnum.Admin, ClientRoleEnum.Owner, ClientRoleEnum.Operations],
        position: 'top',
      },
      {
        label: (
          <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/orders">
            <FormattedMessage id="leftMenu.orders" />
          </ReactRouterLinkWithTestMode>
        ),
        icon: <ReceiptIcon />,
        key: '__orders',
        url: '/orders',
        sub_urls: ['/orders/'],
        roles: [ClientRoleEnum.Admin, ClientRoleEnum.Owner, ClientRoleEnum.Operations],
        position: 'top',
      },
      {
        label: (
          <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/impact-links">
            <FormattedMessage id="leftMenu.impact-links" />
          </ReactRouterLinkWithTestMode>
        ),
        icon: <LinkIcon />,
        key: '__impact-links',
        url: '/impact-links',
        roles: [ClientRoleEnum.Owner, ClientRoleEnum.Admin, ClientRoleEnum.Operations],
        position: 'top',
      },
      {
        label: <Subheader message="leftMenu.title.data" />,
        key: '__data-subheader',
        roles: [
          ClientRoleEnum.Owner,
          ClientRoleEnum.Admin,
          ClientRoleEnum.Developer,
          ClientRoleEnum.Viewer,
          ClientRoleEnum.Operations,
        ],
        position: 'top',
      },
      {
        label: (
          <span className="MenuLeft__menu-item__title">
            <FormattedMessage id="leftMenu.data-collection" />
          </span>
        ),
        icon: <GridConnectIcon />,
        key: '__data-collections',
        url: '/data-collections',
        roles: [
          ClientRoleEnum.Owner,
          ClientRoleEnum.Admin,
          ClientRoleEnum.Developer,
          ClientRoleEnum.Viewer,
          ClientRoleEnum.Operations,
        ],
        position: 'top',
        featureFlags: ['data-collection-outbound-page', 'data-collection-inbound-page'],
        entitlements: [PLATFORM_DATA_COLLECTION_OUTBOUND_ENTITLEMENT, PLATFORM_DATA_COLLECTION_INBOUND_ENTITLEMENT],
        sub_urls: ['/data-collections/outbound/', '/data-collections/inbound/'],
        children: [
          {
            label: (
              <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/data-collections/outbound">
                <FormattedMessage id="leftMenu.data-collection-outbound" />
              </ReactRouterLinkWithTestMode>
            ),
            icon: null,
            key: '__data-collection-outbound',
            url: '/data-collections/outbound',
            roles: [
              ClientRoleEnum.Owner,
              ClientRoleEnum.Admin,
              ClientRoleEnum.Developer,
              ClientRoleEnum.Viewer,
              ClientRoleEnum.Operations,
            ],
            position: 'top',
            featureFlags: ['data-collection-outbound-page'],
            entitlements: [PLATFORM_DATA_COLLECTION_OUTBOUND_ENTITLEMENT],
          },
          {
            label: (
              <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/data-collections/inbound">
                <FormattedMessage id="leftMenu.data-collection-inbound" />
              </ReactRouterLinkWithTestMode>
            ),
            icon: null,
            key: '__data-collection-inbound',
            url: '/data-collections/inbound',
            roles: [
              ClientRoleEnum.Owner,
              ClientRoleEnum.Admin,
              ClientRoleEnum.Developer,
              ClientRoleEnum.Viewer,
              ClientRoleEnum.Operations,
            ],
            position: 'top',
            featureFlags: ['data-collection-inbound-page'],
            entitlements: [PLATFORM_DATA_COLLECTION_INBOUND_ENTITLEMENT],
          },
        ],
      },
      {
        label: (
          <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/imports">
            <FormattedMessage id="leftMenu.data-import" />
          </ReactRouterLinkWithTestMode>
        ),
        icon: <FileArrowUpIcon />,
        key: '__imports',
        url: '/imports',
        sub_urls: ['/imports/'],
        roles: [
          ClientRoleEnum.Owner,
          ClientRoleEnum.Admin,
          ClientRoleEnum.Developer,
          ClientRoleEnum.Viewer,
          ClientRoleEnum.Operations,
        ],
        position: 'top',
      },
      {
        label: (
          <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/directory/supplier">
            <FormattedMessage id="leftMenu.directory" />
          </ReactRouterLinkWithTestMode>
        ),
        icon: <OrganisationIcon />,
        key: '__directory',
        url: '/directory/supplier',
        position: 'top',
        roles: [ClientRoleEnum.Admin, ClientRoleEnum.Developer, ClientRoleEnum.Viewer, ClientRoleEnum.Operations],
        entitlements: [PLATFORM_DIRECTORY_SUPPLIER_ENTITLEMENT],
      },
      {
        label: (
          <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/integrations">
            <FormattedMessage id="leftMenu.integrations" />
          </ReactRouterLinkWithTestMode>
        ),
        icon: <PuzzleIcon />,
        key: '__integrations',
        url: '/integrations',
        roles: [ClientRoleEnum.Owner, ClientRoleEnum.Admin, ClientRoleEnum.Operations, ClientRoleEnum.Developer],
        position: 'top',
      },
      {
        label: (
          <ReactRouterLinkWithTestMode className="MenuLeft__menu-item__title" to="/test-data">
            <FormattedMessage id="leftMenu.test-data" />
          </ReactRouterLinkWithTestMode>
        ),
        icon: <FlaskIcon />,
        key: '__test-data',
        url: '/test-data',
        roles: [ClientRoleEnum.Owner, ClientRoleEnum.Admin, ClientRoleEnum.Operations],
        position: 'top',
      },
      {
        label: (
          <ReactRouterLinkWithTestMode className="pl-1.5 font-medium" to="/developers">
            <FormattedMessage id="leftMenu.developers" />
          </ReactRouterLinkWithTestMode>
        ),
        icon: <DeveloperToolIcon />,
        key: '__developers',
        url: '/developers',
        roles: [ClientRoleEnum.Owner, ClientRoleEnum.Admin, ClientRoleEnum.Developer],
        position: 'bottom',
      },
      {
        label: (
          <a
            id="left-menu-api-docs"
            href="https://docs.pledge.io/docs/public-api"
            className="flex items-center gap-2 pl-1.5 font-medium"
            target="_blank noreferrer"
          >
            <FormattedMessage id="leftMenu.documentation" />
            <ExternalIcon />
          </a>
        ),
        icon: <DocumentApiIcon />,
        key: '__api-docs',
        url: '/api-docs',
        roles: [ClientRoleEnum.Owner, ClientRoleEnum.Admin, ClientRoleEnum.Developer],
        position: 'bottom',
      },
    ],
  });
}
