import { useState, useEffect, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { find } from 'lodash';
import type { Location } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import store from 'store';
import { Layout, Menu } from '@pledge-earth/web-components';
import { Avatar, IconButton, MenuCollapseIcon, MenuExpandIcon, Text } from '@pledge-earth/product-language';
import { useFlags } from '@unleash/proxy-client-react';

import { AvatarImage } from '../AvatarImage/AvatarImage';
import type { AppDispatch } from '../../store/store';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { settingChanged } from '../../store/settings/reducers';
import { getMenuData } from '../../services/menu/getMenuData';
import type { MenuItem, MenuSubHeaderItem } from '../../services/menu/getMenuData';
import type { ClientRoleEnum } from '../../services/graphql/generated';
import { imagesCdnUrl } from '../../utils/cdn';
import { selectEntitlements } from '../../store/user/selectors';

import { TestModeSwitch } from './TestModeSwitch/TestModeSwitch';

export interface MenuLeftProps {
  dispatch: AppDispatch;
  menuData: (MenuItem | MenuSubHeaderItem)[];
  location: Location;
  isMenuCollapsed?: boolean;
  isMobileView?: boolean;
  leftMenuWidth?: number;
  role?: ClientRoleEnum | null;
  testModeEnabled?: boolean;
  client: string | null;
  clientAvatar?: { key: string } | null;
}

export function MenuLeft({
  location: { pathname },
  dispatch,
  menuData,
  isMenuCollapsed,
  isMobileView,
  leftMenuWidth,
  client,
  clientAvatar,
}: MenuLeftProps) {
  const { formatMessage } = useIntl();
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || []);
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || []);

  const applySelectedKeys = useCallback(() => {
    let selectedItem = find(menuData, (item) => {
      if ('url' in item) {
        return item.url === pathname || item.url === pathname.replace('/test', '');
      }
      return false;
    });

    if (!selectedItem) {
      selectedItem = menuData.find((item) => {
        if (item.sub_urls) {
          for (const subUrl of item.sub_urls) {
            if (pathname.replace('/test', '').startsWith(subUrl)) {
              return true;
            }
          }
        }
        return false;
      });
    }

    setSelectedKeys(selectedItem ? [selectedItem.key] : []);
  }, [menuData, pathname]);

  useEffect(() => {
    applySelectedKeys();
  }, [pathname, menuData, applySelectedKeys]);

  const onCollapse = (_value, type) => {
    if (type === 'responsive') {
      return; // Prevent menu collapse when opening print window
    }

    dispatch(
      settingChanged({
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      }),
    );
    setOpenedKeys([]);
  };

  const onOpenChange = (keys) => {
    store.set('app.menu.openedKeys', keys);
    setOpenedKeys(keys);
  };

  const handleClick = (e) => {
    if (e.key === '__api-docs') {
      // Removing the hoverstate from the api docs item when clicked
      // to prevent being active when a user returns to the page
      document
        .getElementById('left-menu-api-docs')
        ?.parentElement?.parentElement?.classList.remove('ant-menu-item-active');
      // Preventing API Docs item from updating selected key
      // as it is an external link
      return;
    }
    store.set('app.menu.selectedKeys', [e.key]);
    setSelectedKeys([e.key]);
  };

  const menuSettings = isMobileView
    ? {
        trigger: null,
        width: leftMenuWidth,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        trigger: null,
        width: leftMenuWidth,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'md' as any,
      };

  return (
    <Layout.Sider
      {...menuSettings}
      className={classNames('MenuLeft', {
        'MenuLeft--mobile': isMobileView,
      })}
    >
      <div
        className={classNames('MenuLeft__wrapper', {
          'MenuLeft__wrapper--mobile': isMobileView,
        })}
      >
        {!isMobileView && (
          <div className="MenuLeft__company">
            <Avatar className="shrink-0" variant="square">
              <AvatarImage src={imagesCdnUrl(clientAvatar?.key)} fallback={client?.charAt(0)?.toUpperCase()} />
            </Avatar>
            <div
              className={classNames('MenuLeft__company__name', {
                'MenuLeft__company__name--hidden': isMenuCollapsed,
              })}
            >
              <Text className="block truncate font-galano-grotesque text-heading-lg font-normal">{client}</Text>
            </div>
            <IconButton
              variant="subtle"
              label={formatMessage({ id: 'expand' })}
              className={classNames('MenuLeft__unfold-button', {
                'MenuLeft__button-show': isMenuCollapsed,
              })}
              onPress={onCollapse as any}
            >
              <MenuExpandIcon />
            </IconButton>
            <IconButton
              variant="subtle"
              label={formatMessage({ id: 'collapse' })}
              className={classNames('MenuLeft__fold-button', {
                'MenuLeft__button-show': !isMenuCollapsed,
              })}
              onPress={onCollapse as any}
            >
              <MenuCollapseIcon />
            </IconButton>
          </div>
        )}

        <div className="MenuLeft__menus-wrapper">
          <Menu
            tabIndex={-1}
            className="MenuLeft__menu"
            onClick={handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="vertical"
            inlineIndent={15}
            style={{ border: 'none' }}
            items={menuData.filter((item) => item.position === 'top')}
          />
          <div>
            <div className="MenuLeft__menu__divider" />
            <Menu
              tabIndex={-1}
              className="MenuLeft__menu"
              onClick={handleClick}
              selectedKeys={selectedKeys}
              openKeys={openedKeys}
              onOpenChange={onOpenChange}
              mode="inline"
              inlineIndent={15}
              style={{ border: 'none', paddingTop: '4px' }}
              items={menuData.filter((item) => item.position === 'bottom')}
            />
            <TestModeSwitch />
          </div>
        </div>
      </div>
    </Layout.Sider>
  );
}

export function MenuLeftContainer() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isMenuCollapsed = useAppSelector((state) => state.settings.isMenuCollapsed);
  const isMobileView = useAppSelector((state) => state.settings.isMobileView);
  const leftMenuWidth = useAppSelector((state) => state.settings.leftMenuWidth);
  const entitlements = useAppSelector(selectEntitlements);
  const { role, client, client_avatar: clientAvatar } = useAppSelector((state) => state.user);

  const flags = useFlags();

  const menuData = useMemo(
    () =>
      getMenuData({
        currentLocation: location.pathname,
        entitlements,
        flags,
        role,
      }),
    [entitlements, flags, location.pathname, role],
  );

  return (
    <MenuLeft
      dispatch={dispatch}
      menuData={menuData}
      isMenuCollapsed={isMenuCollapsed}
      isMobileView={isMobileView}
      leftMenuWidth={leftMenuWidth}
      role={role as ClientRoleEnum}
      client={client}
      clientAvatar={clientAvatar}
      location={location}
    />
  );
}
